<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-09-24 11:36:56
 * @LastEditTime: 2021-12-24 16:24:46
-->
<template>
  <div class="contactUs">
    <div class="contactUs-inner">
      <div class="contactUs-map">
        <baidu-map
          class="map"
          :scroll-wheel-zoom="true"
          :double-click-zoom="false"
          :center="center"
          :zoom="zoom"
          @ready="handler"
        >
          <!-- <bm-marker
            :position="center"
            :dragging="true"
            :icon="{
              url: 'http://fhld-static-dev.oss-cn-hangzhou.aliyuncs.com/upload/4d007ecf2d1249159795a9254b247c76.png',
              size: { width: 48, height: 48 },
            }"
          ></bm-marker> -->
          <bm-circle :center="center" :radius="150" stroke-color="white" fill-color="#146cff" :fill-opacity="0.2" :stroke-opacity="1" :stroke-weight="2" @lineupdate="updateCirclePath"></bm-circle>
        </baidu-map>
      </div>
      <div class="contactUs-area">
        <div class="contactUs-name">{{ companyDetail.name }}</div>
        <div class="contactUs-label">公司地址：</div>
        <div class="contactUs-value">
          {{ companyDetail.area }}
        </div>
        <div class="contactUs-label">联系电话：</div>
        <div class="contactUs-value">
          {{ companyDetail.phone }}
        </div>
        <div class="contactUs-label">邮箱：</div>
        <div class="contactUs-value">
          {{ companyDetail.email }}
        </div>
        <div class="contactUs-label">工作时间：</div>
        <div class="contactUs-value">
          {{ companyDetail.workTime }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 3,
      companyDetail: {
        name: "杭州荣鼎科技有限公司",
        area: "浙江省杭州市上城区九环路9号国家大学科技园B幢",
        phone: "0571-8806-5309",
        email: "biz@rongdingplus.com",
        workTime: "早上8:30—晚上6:00",
      },
    };
  },
  mounted() {},
  methods: {
    handler({ BMap, map }) {
      console.log(BMap, map);
      this.center.lng = 120.260646;
      this.center.lat = 30.317228;
      this.zoom = 17;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.BMap_cpyCtrl {
  display: none;
}
/deep/.anchorBL {
  display: none;
}
.contactUs {
  width: 100%;
  height: 100%;

  .contactUs-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 115px;
    .contactUs-map {
      width: 640px;
      height: 520px;
      margin-right: 78px;
      border-radius: 16px;
      .map {
        width: 640px;
        height: 520px;
        border-radius: 16px;
      }
    }
    .contactUs-area {
      .contactUs-name {
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 37px;
        color: #ffffff;
        margin-bottom: 10px;
      }
      .contactUs-label {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 28px;
        color: #999999;
        padding-top: 20px;
      }
      .contactUs-value {
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 28px;
        color: #ffffff;
        padding: 10px 0 30px 0;
        border-bottom: 1px solid #707070;
      }
    }
  }
}
</style>
